import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { actionToProps as jobsAction } from 'store/reducers/news/actions/actionGetJobs';
import { Box, Grid, FormControl, Select, MenuItem } from '@mui/material';

import IconSearch from 'assets/images/icon-search.svg';
import IconLocation from 'assets/images/map-pin.svg';
import countryData from '../../constant/country.json';
import stateData from '../../constant/state.json';
import cityData from '../../constant/city.json';
import { COUNTRY_ID } from 'constant';
const defaultFilter = {
  state: '',
  city: '',
  country: '',
  keyword: ''
};

const defaultFilterLocation = {
  country: '',
  city: '',
  state: ''
};
const DashboardHeader = (props) => {
  const { onSearchJobResult } = props;

  const [filter, setFilter] = useState(defaultFilter);
  const [filterLocation, setFilterLocation] = useState(defaultFilterLocation);

  const onClickSearchJob = () => {
    let findFilterByText = {
      country: filterLocation?.country?.label || '',
      state: filterLocation?.state?.id || '',
      city: filterLocation?.city?.id || ''
    };

    if (filter?.keyword !== '') {
      findFilterByText = {
        ...filter,
        title: filter?.keyword
      };
    }

    localStorage.setItem('workium_jobFilter', JSON.stringify(findFilterByText));
    onSearchJobResult(findFilterByText);
  };

  useEffect(() => {
    const savedFilter = localStorage.getItem('workium_jobFilter');
    if (savedFilter) {
      const parsedFilter = JSON.parse(savedFilter);
      setFilter(parsedFilter);
      let country = countryData.find((e) => e.country_name == parsedFilter.country);
      let state = stateData.find((e) => e.state_id == parsedFilter.state);
      let city = cityData[2].data.find((e) => e.city_id == parsedFilter.city);
      if (country) {
        country = {
          label: country.country_name,
          id: country.country_id
        };
      }
      if (state) {
        state = {
          label: state.state_name,
          id: state.state_id
        };
      }
      if (city) {
        city = {
          label: city.city_name,
          id: city.city_id
        };
      }

      setFilterLocation({
        country,
        state,
        city
      });
    }
  }, []);

  return (
    <>
      <div className="dahboard-header header-search">
        <div className="d-flex">
          <div className="w-100" style={{ marginTop: 11 }}>
            <Grid container spacing={2}>
              <Grid item md={3} style={{ paddingTop: 3 }}>
                <div className="input-inline-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Job Title"
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        keyword: e.target.value
                      })
                    }
                    value={filter.keyword}
                    style={{
                      paddingTop: 0,
                      marginTop: -3,
                      height: 45,
                      borderRadius: 8
                    }}
                  />
                  <span className="pl-1 pr-1">
                    <img src={IconSearch} style={{ width: '20px' }} alt="" />
                  </span>
                </div>
              </Grid>
              <Grid item md={3} style={{ paddingTop: 0 }}>
                <div className="input-inline-group">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={stateData
                      .filter((f) => +f.country_id == COUNTRY_ID)
                      .map((e) => {
                        return {
                          label: e.state_name,
                          id: e.state_id
                        };
                      })}
                    onChange={(e, value) => {
                      setFilterLocation({
                        ...filterLocation,
                        state: value
                      });
                    }}
                    value={filterLocation?.state}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="State (Optional) "
                        InputLabelProps={{ shrink: false }}
                        variant="outlined"
                        className="form-control"
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item md={3} style={{ paddingTop: 0 }}>
                <div className="input-inline-group">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={cityData[2].data
                      .filter((f) => f.state_id == filterLocation?.state?.id)
                      .map((e) => {
                        return {
                          label: e.city_name,
                          id: e.city_id
                        };
                      })}
                    onChange={(e, value) => {
                      setFilterLocation({
                        ...filterLocation,
                        city: value
                      });
                    }}
                    value={filterLocation?.city}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="City (Optional) "
                        InputLabelProps={{ shrink: false }}
                        variant="outlined"
                        className="form-control"
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="col-btn">
            <button className="btn btn-success" onClick={onClickSearchJob}>
              {'Search Jobs'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

DashboardHeader.defaultProps = {
  onFilter: () => false,
  title: 'My Job Posts',
  isSearchShown: true
};

const mapStateToProps = (state) => {
  return {
    news: state.news,
    auth: state.auth
  };
};
const mapDispatchToProps = { ...jobsAction };
export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
